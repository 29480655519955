<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    class="content-wrapper container-xxl p-0"
  >
    <div class="content-header row">
      <div class="content-header-left col-md-9 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-start mb-0">
              {{ convo.title }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <div class="row">
        <div class="col-sm-6">
          <p class="mb-3">
            <a
              v-for="file in convo.support"
              :key="file.id"
              :href="file.url"
              target="_blank"
              class="btn btn-sm btn-flat-secondary"
            ><i data-feather="info" /> {{ file.name }}</a>
          </p>
          <!-- <p v-html="convo.description" /> -->
          <p>
            <span class="text-dark"><span class="badge bg-white">..</span> Pending to be filled in</span>
            <span class="ms-1 text-dark"><span class="badge bg-warning">..</span> Partially filled in</span>
            <span class="ms-1 text-dark"><span class="badge bg-success">..</span> Section completed</span>
          </p>
        </div>
        <div class="col-sm-6 ">
          <div class="row">
            <div class="col-sm-9 text-end">
              <p>Time left<br><span class="display-6 text-danger">{{ remainingTime }}</span></p>
            </div>
            <div class="col-sm-3 text-end">
              <p>Form filled<br><span class="display-6 text-success">{{ percentage.toFixed(2) }}%</span></p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!loggedUser.email_verified"
        class="row"
      >
        <div class="col-sm-12">
          <div
            v-if="!emailSended"
            class="alert alert-danger"
          >
            <div
              v-if="!sending"
              class="alert-body"
            >
              <p>You have not yet confirmed your email: <strong>{{ loggedUser.email }}</strong>. Please check your inbox and/or your spam folder to continue the process. <a
                href="#"
                class="text-dark text-decoration-underline"
                @click="resendEmail"
              >Resend confirmation email</a>.</p>
            </div>
            <div
              v-else
              class="card-body"
            >
              <div
                class="col-12 text-center no-result no-items"
              >
                <div class="loading">
                  <div class="effect-1 effects" />
                  <div class="effect-2 effects" />
                  <div class="effect-3 effects" />
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="emailSended"
            class="alert alert-success"
          >
            <div class="alert-body">
              <p>We have sent you an email again to confirm it.</p>
            </div>
          </div>
        </div>
      </div>

      <section
        id="senior__call__index"
        class="senior__call__index"
      >
        <div class="row">
          <div class="col">
            <div class="card mt-2">
              <div class="card-body">
                <div class="row d-flex align-items-stretch">
                  <Section
                    v-for="(section, index) in sections"
                    :key="section.id"
                    :section="section"
                    :index="index"
                    :convo-id="convo.id"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row senior__call__form__actions mt-0">
            <div class="col-12">
              <hr>
            </div>
            <div class="col">
              <a
                class="btn btn-warning text-dark"
                @click="goToPreview()"
              >Preview</a>
              <a
                v-if="convo.open_applicants && !disableButton"
                class="btn btn-success ms-1 text-dark"
                @click="$emit('showModal', !showModal)"
              >Submit candidacy</a>
              <div
                id="disabledButton"
                class="d-inline-block mb-2"
              >
                <button
                  v-if="convo.open_applicants && disableButton"
                  disabled
                  class="btn btn-success ms-1 text-dark"
                >
                  Submit candidacy
                </button>
              </div>
              <b-tooltip
                :key="`field-custom-${convo.id}`"
                placement="topleft"
                boundary="document"
                triggers="hover"
                :target="`disabledButton`"
                :title="'You need to fill in all the sections before submitting your candidacy'"
              />
              <!-- añadir clase disabled si hay campos pendientes de rellenar -->
              <!-- el botón ok del modal de confirmación, quitarlo y que después de 2 segundos salte a finalize-ok.php -->
              <p>
                <small>Applicants can submit their applications regardless of whether the referees and the host(s) institutions have uploaded their letters and EOIs. The system allows applicants to monitor file uploading by both referees and host institution(s). Please bear in mind that <strong>incomplete applications will not be considered for evaluation.</strong></small>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BTooltip } from 'bootstrap-vue'
import Section from './partials/Section.vue'

export default {
  components: {
    Section,
    BTooltip,
  },
  data() {
    return {
      disableButton: false,
      percentage: 0,
      showModal: false,
      emailSended: false,
      remainingTime: null,
      sending: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      sections: 'convo/sections',
      loggedUser: 'auth/user',
    }),
  },
  mounted() {
    const outside = this
    const lengthSections = this.sections.length
    const singleField = 100 / lengthSections

    this.sections.forEach(item => {
      if (item.status !== 2) {
        outside.disableButton = true
      } else {
        outside.percentage += singleField
      }
    })

    this.generateCountdown()
  },
  methods: {
    generateCountdown() {
      if (this.convo.open_applicants) {
        const end = new Date(this.convo.current_access.pivot.end_date)
        const outside = this

        const second = 1000
        const minute = second * 60
        const hour = minute * 60
        const day = hour * 24
        let timer

        // eslint-disable-next-line no-inner-declarations
        function showRemaining() {
          const now = new Date()
          const distance = end - now
          if (distance < 0) {
            clearInterval(timer)
            return
          }
          const days = Math.floor(distance / day)
          const hours = Math.floor((distance % day) / hour)
          const minutes = Math.floor((distance % hour) / minute)

          outside.remainingTime = `${days < 10 ? `0${days}` : days}d:${hours < 10 ? `0${hours}` : hours}h:${minutes < 10 ? `0${minutes}` : minutes}m`
        }

        timer = setInterval(showRemaining, 1000)
      } else {
        this.remainingTime = '00d:00h:00m:00s'
      }
    },
    goToPreview() {
      if (this.$route.name.includes('senior-call')) {
        this.$router.push({ name: 'front.senior-call-preview' })
      } else if (this.$route.name.includes('academy-call')) {
        this.$router.push({ name: 'front.academy-call-preview' })
      }
    },
    resendEmail() {
      this.sending = true
      const routeOkUrl = this.$router.resolve({
        name: 'confirmed-email',
      }).href

      const absoluteURL = new URL(routeOkUrl, window.location.origin).href
      this.$store.dispatch('auth/resendEmailVerification', {
        user_id: this.loggedUser.id,
        route_ok: absoluteURL,
      }).then(() => {
        this.emailSended = true
        this.sending = false
      })
    },
  },
}
</script>
