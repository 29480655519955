<!-- eslint-disable vue/html-self-closing -->
<template>
  <div>
    <div
      id="modalSumitSeniorCall"
      class="modal fade text-start"
      :class="showModal ? 'show' : ''"
      :style="{ display: showModal ? 'block' : 'none' }"
      tabindex="-1"
      aria-labelledby="modalSumitSeniorCallLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1
              id="modalSumitSeniorCallLabel"
              class="modal-title text-dark"
            >
              YOU ARE GOING TO SUBMIT YOUR CANDIDACY
            </h1>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="toggleModal"
            ></button>
          </div>
          <div class="modal-body">
            <p>
              You are going to submit your candidacy to the
              <strong v-if="convo">{{ convo.title }}</strong>, once submitted you will be able to
              modify your data until:
            </p>
            <h3>{{ convo.end_date_formatted }} at {{ convo.end_hour_formatted }} p.m. CET</h3>
            <p>
              After this date you will not be able to make any changes and you
              will only be able to view the sent data.
            </p>
            <hr />
            <p>You will receive in your mail:</p>
            <h3 v-if="user">
              {{ user.email }}
            </h3>
            <p>
              proof of receipt of your application. If not, check your spam folder.
            </p>
          </div>
          <div class="modal-footer d-flex justify-content-between">
            <!-- <button type="button" class="btn btn-success" data-bs-dismiss="modal">Submit candidacy</button> -->
            <button
              id="type-success"
              type="button"
              class="btn btn-success"
              data-bs-dismiss="modal"
              @click="sendConvo"
            >
              Submit candidacy
            </button>
            <button
              type="button"
              class="btn btn-outline-danger"
              data-bs-dismiss="modal"
              @click="toggleModal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      class="offcanvas-backdrop fade"
      :class="showModal ? 'show' : ''"
      @click="toggleModal()"
    ></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import 'sweetalert2/dist/sweetalert2.min.css'

export default {
  components: {},
  props: {
    show: { type: Boolean, required: true },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      sended: 'convo/sended',
      user: 'auth/user',
    }),
  },
  watch: {
    show() {
      this.toggleModal()
    },
  },
  methods: {
    async sendConvo() {
      if (this.convo.open_applicants) {
        await this.$store.dispatch('convo/sendConvo', this.convo)
        if (this.sended) {
          if (this.$route.name.includes('senior-call')) {
            this.$router.push({ name: 'front.senior-call-sent' })
          } else if (this.$route.name.includes('academy-call')) {
            this.$router.push({ name: 'front.academy-call-sent' })
          }
        } else {
          Vue.swal('The call couldn\'t be send.', '', 'error')
        }
      } else {
        Vue.swal('The call couldn\'t be send.', '', 'error')
      }
    },
    async toggleModal() {
      this.showModal = !this.showModal
    },
  },
}
</script>
