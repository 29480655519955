<!-- eslint-disable vue/html-self-closing -->
<template>
  <div
    class="col-md-6 senior__call__index__element d-flex align-items-stretch"
    @click="openSection(section.id)"
  >
    <a class="d-flex align-items-stretch">
      <div class="senior__call__index__element-check">
        <span
          :class="{
            'bg-success': section.status == 2,
            'bg-warning': section.status == 1,
          }"
          class="badge ml-auto badge-pill d-flex align-items-center justify-content-center"
        >
          <img
            v-if="section.status == 2"
            :src="require('@/assets/icons/icon-check.svg')"
            alt="do"
          />
          <span v-else>
            {{ index + 1 }}
          </span>
        </span>
      </div>
      <div class="senior__call__index__element-text">
        <p>
          <strong>{{ section.title }}</strong>
          <span
            v-if="section.settings[0] && section.settings[0].key == 'required'"
            class="badge bg-success ms-1 mr-1 ml-auto badge-pill"
            :class="{'bg-success': section.settings[0].value >= section.settings[0].requeriment, 'bg-danger': section.settings[0].value < section.settings[0].requeriment}"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            data-bs-original-title="Covered minimum"
          > {{ section.settings[0].value }}/{{ section.settings[0].requeriment }} </span>
        </p>
        <p>
          <!-- {{ section.description }} -->
        </p>
      </div>
      <div
        class="senior__call__index__element-arrow d-flex align-items-center justify-content-center"
      >
        <img
          :src="require('@/assets/icons/icon-arrow-right.svg')"
          alt="continue"
        />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    section: { type: Object, required: true },
    index: { type: Number, required: true },
    convoId: { type: Number, required: true },
  },
  data() {
    return {}
  },
  methods: {
    openSection(id) {
      const { convoId } = this
      if (this.$route.name.includes('senior-call')) {
        this.$router.push({
          name: 'front.senior-call-section',
          params: {
            convo_id: convoId,
            id,
          },
        })
      } else if (this.$route.name.includes('academy-call')) {
        this.$router.push({
          name: 'front.academy-call-section',
          params: {
            convo_id: convoId,
            id,
          },
        })
      }
    },
  },
}
</script>
